import request from './request'

/**
 * 公告数据
 */
export function ggListApi(){
  return request.get(`index/ggList`);
}

/**
  * @description 商品列表
  */
export function initApi() {
  return request.get(`index/init`);
}

/**
  * @description 商品列表
  */
export function goodsListApi(data) {
  return request.get(`goods/goodsList`,data);
}

/**
 * @description 商品组
 */
export function goodsGroupApi(){
  return request.get(`goods/groupList`);
}
/**
 * @description 商品状态启用
 */
export function checkKtApi(data){
  return request.post(`goods/changeKt`,data);
}
/**
 * @description 平台列表
 */
export function platformListApi(data){
  return request.get(`account/platformlist`,data);
}
/**
 * @description 账号数据
 */
export function accountDataApi(){
  return request.get(`account/accountData`);
}

/**
 * @description 账号列表
 */
export function accountListApi(data){
  return request.get(`account/accountlist`,data);
}

/**
 * 获取授权URL
 * @param {*} data 
 * @returns 
 */
export function webUrlApi(data){
  return request.post(`account/webUrl`,data);
}

/**
 * 获取商户授权URL
 * @param {*} data 
 * @returns 
 */
export function shopWebUrlApi(data){
  return request.post(`shop_account/webUrl`,data);
}

/**
 * 通过code创建用户
 * @param {*} data 
 * @returns 
 */
export function createAccountApi(data){
  return request.post(`account/createAccount`,data);
}

/**
 * 一键托管
 * @returns 
 */
export function hostingAccountApi(){
  return request.post(`account/hostingAccount`);
}

/**
 * 关闭托管
 * @returns 
 */
export function closeHostingApi(data){
  return request.post(`account/closeHostring`,data);
}

/**
 * 开启托管
 * @returns 
 */
export function startHostringApi(data){
  return request.post(`account/startHostring`,data);
}


//任务板块
export function maxCountApi(){
  return request.get(`task/getTaskData`);
}

/**
 * 标签内容
 * @param {*} data 
 * @returns 
 */
export function goodsTitleListApi(data){
  return request.get(`task/titleList`,data);
}

/**
 * AI创建标签内容
 * @param {*} data 
 */
export function createGoodsTitleApi(data){
  return request.post(`task/createTitle`,data);
}

/**
 * 创建任务
 */

export function createTaskApi(data){
  return request.post(`task/createTask`,data);
}
/**
 * 任务列表
 * @param {*} data 
 * @returns 
 */
export function taskListApi(data){
  return request.get(`task/taskList`,data);
}

/**
 * 发布任务列表
 * @param {*} data 
 * @returns 
 */
export function publishListApi(data){
  return request.get(`task/publishList`,data);
}
/**
 * 取消任务
 * @param {*} data 
 * @returns 
 */
export function setStatusApi(data){
  return request.get(`task/set_status`,data);
}
/**
 * 视频列表
 */
export function videoListApi(data){
  return request.get(`task/videoList`,data);
}

/**
 * 删除
 * @param {*} data 
 * @returns 
 */
export function delVideoApi(data){
  return request.get(`task/delVideo`,data);
}

/**
 * 商品详情
 * @param {*} data 
 * @returns 
 */
export function goodsDetailApi(data){
  return request.get(`goods/detail`,data);
}

/**
 * 添加商品
 */
export function addGoodsApi(data){
  return request.post(`goods/createGoods`,data);
}

/**
 * 场景分类
 * @returns 
 */
export function sceneCateApi(){
  return request.get(`task/sceneCateList`);
}
/**
 * 场景内容 
 * @param {*} data 
 * @returns 
 */
export function sceneListApi(data){
  return request.get(`task/sceneList`,data);
}
/**
 * 背景分类
 * @returns 
 */
export function backCateApi(){
  return request.get(`attachment/categoryList`);
}

/**
 * 背景内容 
 * @param {*} data 
 * @returns 
 */
export function backListApi(data){
  return request.get(`attachment/backList`,data);
}

/**
 * 发布任务
 * @param {*} data 
 * @returns 
 */
export function publishOneApi(data){
  return request.post(`task/publishOne`,data);
}

/**
 * 获取SchemaUrl
 * @param {*} data 
 * @returns 
 */
export function getSchemaUrl(data){
  return request.post(`task/getSchemaUrl`,data);
}

/**
 * 货架明细
 * @param {*} data 
 * @returns 
 */
export function merGoodsListApi(data){
  return request.get(`goods/merGoodsList`,data);
}

export function allAccountListApi(data){
  return request.get(`account/allList`,data);
}

export function allGoodsListApi(data){
  return request.get(`goods/allGoodsList`,data);
}

export function addMerGoodsApi(data){
  return request.post(`goods/addMerGoods`,data);
}

