
<template>
    <div>
            <div class="box BOX-card">
              <div></div>
                <div class="tzggtitle">
                    <!-- <img class="tzggicon" src="@/assets/hld.png" alt="" /> -->
                     <div style="width: 240px;cursor: pointer;" @click="goBack">
                      <i class="el-icon-back " style="font-size: 25px;"></i>
                      <span> 上架商品</span>
                     </div>
                    
                </div>
                <div class="infobox">
                    <div class="dialog-content-box">
                        <div class="pl-box-item" :class="item.value==platform?'pl-active':''" v-for="(item,index) in platformList" :key="index" @click="choseP(item.value)">
                        <img class="pl-image" :src="item.image" > 
                        <div  class="pl-name">{{ item.name }}</div>
                    </div>
                    </div>
                    
                  <div class="leftbox">
                        <div class="infotitle">选择商品</div>
                        <div class="ch-box">
                            <div class="left-card" style="width: 50%;">
                                <el-table
                              :data="accountList"
                              style="width: 100%; background-color: #ecf2f9"
                              max-height="500"
                              :header-cell-style="{
                                backgroundColor: '#ECF2F9',
                                color: '#000',
                                textAlign:'center',
                                fontSize: '16px',
                                height:'40px'
                              }"
                              @selection-change="handleAccountChange"
                            >
                            <el-table-column type="selection" width="80"> </el-table-column>
                              <el-table-column prop="name" label="账号列表" min-width="260">
                                <template slot-scope="scope">
                                  <img class="hoverImg" :src="scope.row.avatar"/>
                                  <span class="nickname" >{{scope.row.nickname}}</span>
                                </template>
                              </el-table-column>
                            </el-table>
                            <el-empty v-if="goodsList.length<=0" description=" " style="width: 200px;height: 200px;margin: 0 auto;"></el-empty>
                            </div>
                            <div class="right-card" style="width: 50%;">
                                <div >

                                  <el-table
                              :data="goodsList"
                              style="width: 100%; background-color: #ecf2f9"
                              max-height="500"
                              :header-cell-style="{
                                backgroundColor: '#ECF2F9',
                                color: '#000',
                                textAlign:'center',
                                fontSize: '16px',
                              }"
                              @selection-change="handleSceneChange"
                            >
                            <el-table-column type="selection" width="80"> </el-table-column>
                            <el-table-column prop="goods_thumb" label="商品图" min-width="120">
                              <template slot-scope="scope">
                                  <div style="display: flex;">
                                    <img v-if="scope.row.thumb_image" style="width: 80px;height: 80px;border-radius: 8px;" :src="scope.row.thumb_image"/>
                                  </div>
                              </template>
                            </el-table-column>
                            <el-table-column prop="title" label="关联商品" min-width="260">
                            </el-table-column>
                            </el-table>
                            <el-empty v-if="goodsList.length<=0" description=" " style="width: 200px;height: 200px;margin: 0 auto;"></el-empty>

                                </div>
                            </div>
                        </div>
                      
                 
                  </div>
                </div>

                <div class="botmbtnbox">
                    
                    <div class="bigbtn" style="" @click="createTask">立即上架</div>
                </div>
            </div>
    


      
    </div>
  </template>
<script>

  import {allAccountListApi,allGoodsListApi,addMerGoodsApi} from '@/api/api.js';
  export default {
    props: ['addModal'],
    data() {
      return {
        chooseModal:false,
        goodsDrawerModal:false,
        
        goods:[],
        radio: "1",
        num: 1,
        accountList: [ ],
        goodsList:[],
        goods:{},
        titleList:[],
        keyword:'',
        loading:false,
        categroyData:[],
        back_ids:[],
        platform:'fasthand',
        accountData:[],
        GoodsData:[],
      };
    },
    computed:{
    platformList(){
       
      return this.$store.getters.platformList;
    }
  },
    mounted() {
      this.getAccountList();
      this.getGoodsList();
      console.log(this.platformList);
    },
    methods: {
        choseP(p){
            this.platform=p;
            this.getAccountList();
            
        },
        getAccountList(){
          allAccountListApi({platform:this.platform}).then(res=>{
            this.accountList=res.data;
          });
        },
        getGoodsList(){
          allGoodsListApi({platform:this.platform}).then(res=>{
            this.goodsList=res.data;
          });
        },
      //数组去重
      removeDuplicates(arr) {
        return [...new Set(arr)];
      },
      deduplicatedArray(originalArray) {
            // Flatten the multi-dimensional array into a one-dimensional array
            const flatArray =originalArray.flat();

            // Remove duplicates using a Set
            const uniqueArray = [...new Set(flatArray)];

            // Optionally, you can convert the unique array back to multi-dimensional array
            // Here, we just return the unique array for simplicity
            return uniqueArray;
        },
        goBack(){
        this.$router.go(-1);
      },
      handleSceneChange(val){
        let that=this;
        
        val.forEach(res => {
          that.GoodsData.push(res.unique_id)
        });
        that.GoodsData=that.deduplicatedArray(that.GoodsData);
      },
      handleAccountChange(val){
        let that=this;
        val.forEach(res => {
          that.accountData.push(res.id)
        });
        that.accountData=that.deduplicatedArray(that.accountData);
      },
      createTask(){
        console.log(this.GoodsData);
        console.log(this.accountData);
        addMerGoodsApi({goodsData:this.GoodsData.toString(),accountData:this.accountData.toString()}).then(res=>{
          this.$message.success(res.msg);
          this.GoodsData=[];
          this.accountData=[];
        });
      }

    },
  };
  </script>
    
    <style scoped lang="scss">
  .hoverImg{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.dialog-content-box{
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    .pl-box-item{
      padding:20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 30px;
      border-radius:10px ;
      width: 150px;
      height: 150px;
      justify-content: center;
      .pl-image{
        width: 100px;
        height: 100px;
      }
      .pl-name{
        font-size: 16px;
        font-weight: 600;
        margin-top: 5px;
        color:#000;
      }
    }
  }
  .pl-active{
    border: 1px solid red;
    
  }
    :deep(.el-table td), 
  :deep(.el-tabl th) {
    text-align: center !important;
  }
  :deep(.el-tree-node__content){
    height: 46px;
  }
  :deep(.el-tree-node__content .el-tree-node__label){
    font-size: 16px;
    font-weight: 700;
  }
  :deep(.el-tree-node__content:hover){
    background-color:#f4f5f9;
    border-radius: 8px;
  }
  :deep(.el-tree-node__expand-icon){
    font-size: 20px;
    color: #333;
  }

  .ch-box{
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: space-around;
    .left-card{
      width: 30%;
      padding:10px;
      height: 500px;
      overflow-y: auto;
      border-right: 2px solid #f5f5f5;
    }
    .right-card{
      width: 68%;
      padding:10px;
      .card-con{
        background-color: #ECF2F9;
        height: 500px;
        border-radius: 10px;

        
      }
    }
  }
  .card-img-box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 500px;
    overflow-y: auto;
    .item-img{
        position: relative;
        width: calc(24% - 5px);
        height: fit-content;
        line-height: 1;
        margin: 5px;
        border-radius: 8px;
        overflow: hidden;
        background: #F5F5F5;
        cursor: pointer;
        transform-origin: 50% 0;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
   
        .back-img{
          width: 138px;
          height: 192px;
        }
      }
  }

  .video-title{
    white-space: nowrap;       /* 禁止文本换行 */
    overflow: hidden;          /* 隐藏超出容器的部分 */
    text-overflow: ellipsis;   /* 使用省略号表示溢出部分 */
    width: 300px;    
  }
  .tags-box{
    display: flex;
    justify-content: center;
    display: grid;
            grid-template-columns: repeat(6, 1fr); /* 每行显示10个元素 */
            gap: 10px; /* 元素之间的间距 */
            margin: 10px;
  }
  .tags-box-item{
    padding: 5px 15px;
    color: #fff;
    background: #B4C5FF;
    margin: 10px;
    border-radius: 50px;
    white-space: nowrap;      /* 禁止换行 */
     overflow: hidden;         /* 隐藏超出部分 */
     text-overflow: ellipsis;  /* 用省略号代替溢出部分 */
     display: flex;
     align-items: center;
    
  }
  .tags-title{
     white-space: nowrap;      /* 禁止换行 */
     overflow: hidden;         /* 隐藏超出部分 */
     text-overflow: ellipsis;  /* 用省略号代替溢出部分 */
      
   }
  
  .scene-title{
    white-space: nowrap;       /* 禁止文本换行 */
    overflow: hidden;          /* 隐藏超出容器的部分 */
    text-overflow: ellipsis;   /* 使用省略号表示溢出部分 */
    display: flex;
    justify-content: flex-start;
  }
  .tooltip-item{
    width: 300px; 
    word-wrap: break-word;    /* 长单词换行 */
    white-space: normal;      /* 正常的空白处理，允许换行 */
  }
    .BOX-card{
        border-radius: 10px;;
    }
    .white-back{
        background: #ffffff !important;
    }
    .table-box{
      padding:20px;
    }
    .el-table{
      border-radius: 10px;
    }
    .el-table .warning-row {
      background: oldlace;
    }

    .el-table .success-row {
      background: #f0f9eb;
    }
  .box {
    width: 100%;
    // height: calc(100vh - 100px);
    padding:  20px 40px;
    background: #e1eaf2;
    display: flex;
    flex-direction: column;
    
    .tzggtitle {
      font-size: 20px;
      color: #000;
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    .tzggicon {
      width: 6px;
      height: 18px;
      margin-right: 4px;
    }
    .right-box{
      min-height: 300px;
      width: 50%;
      margin-left: 80px;
      h1{
        font-size: 16px;
        font-weight: 700;
        padding:10px;
        text-align: center;
      }
    }
  
    .infobox {
      width: 100%;
      // height: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .leftbox {
        // height: 280px;
        background-color: #fff;
        border-radius: 10px;
        width:100%;
        margin-bottom: 40px;
        .leftbtm {
          padding: 20px 40px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          .xzshop {
           padding:20px;
            // background: #ecf2f9;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 40px;
            img {
              width: 200px;
              height: 200px;
              margin-bottom: 10px;
              border-radius: 10px;
            }
            img:hover {
              cursor: pointer;
            }
          }
        }
  
        .guazbox {
          width: 360px;
          display: flex;
          align-items: center;
          font-weight: bold;
          margin: 30px 0;
          .radiobox {
            flex: 1;
            padding: 0 10px;
            box-sizing: border-box;
          }
        }
  
        .fabubox {
          width: 360px;
          display: flex;
          align-items: center;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
  
          .inputbox {
            height: 40px;
            width: 230px;
            display: flex;
            background: #ecf2f9;
            align-items: center;
            // justify-content: space-around;
            color: #2758ff;
            font-size: 16px;
            padding: 0 6px;
            border-radius: 3px;
            margin-left: 10px;
  
            ::v-deep .el-input__inner {
              background: #ecf2f9;
              border: none;
            }
          }
        }
      }
    }
  }
  
  .inputbox {
    height: 40px;
    width: 230px;
    display: flex;
    background: #ecf2f9;
    align-items: center;
    // justify-content: space-around;
    color: #2758ff;
    font-size: 16px;
    padding: 0 6px;
    border-radius: 3px;
    margin-left: 10px;
  
    ::v-deep .el-input__inner {
      background: #ecf2f9;
      border: none;
    }
  }
  
  .infotitle {
    font-size: 16px;
    color: #000;
    font-weight: 700;
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 20px;
    box-sizing: border-box;
  }
  .fabubox {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .botmbtnbox {
    width: 100%;
    height: 90px;
    // background: #ffffff;
    // box-shadow: 0px 0px 12px 0px rgba(43, 108, 143, 0.1);
    margin-top: -30px;
    z-index: 88 !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    .bigbtn:hover {
      cursor: pointer;
    }
    .bigbtn {
      height: 50px;
      border-radius: 8px;
      border: 1px solid #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      background: #2758ff; color: #fff;border:none;width: 400px;margin: 0 auto;
    }
  }
  
  .fabubtm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .fabubtmleft {
      width: 65%;
      padding: 20px;
      box-sizing: border-box;
    }
    .fabubtmright {
      width: 35%;
      padding: 20px;
      box-sizing: border-box;
      .headbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
  
        font-weight: 400;
        font-size: 20px;
        color: #000000;
        span {
          color: #2758ff;
        }
      }
  
      .rightbtm {
        width: 100%;
        padding: 10px 0;
        overflow-y: auto;
        height: 350px;
        .heng {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
        }
      }
    }
  }

  .add-btn{
    height: 40px !important;
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: center;
    font-size: 14px;
  }
  </style>
    