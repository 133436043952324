<template>
    <div>
      <div class="BOX">
        <div class="tzggtitle">
          <img class="tzggicon" src="@/assets/hld.png" alt="" />
          货架列表
        </div>
        <div class="acccont">
          <div class="header-box">
            <div class="select-box">
              <el-select v-model="formData.group_id" placeholder="选择任务状态" @change="formChange">
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :clearable="true"
                  :disabled="item.disabled">
                </el-option>
              </el-select>
  
              <el-input class="searchkey" v-model="formData.keyword" placeholder="请输入关键词" :clearable="true"></el-input>
              <el-button class="searchBtn" @click="search">搜索</el-button>
            </div>
            <el-button class="addBtn" @click="addTask">添加商品到货架</el-button>
          </div>
          <div class="acctablebox">
            <el-table
              :data="goodsList"
              style="width: 100%; background-color: #ecf2f9"
              height="600"
              :row-class-name="tableRowClassName"
              :header-cell-style="{
                backgroundColor: '#ECF2F9',
                color: '#000',
                fontSize: '16px',
                textAlign:'center'
              }"
            >
              <el-table-column prop="id" label="ID" min-width="80">
              </el-table-column>
              <el-table-column prop="title" label="推广账号" min-width="120">
                <template slot-scope="scope">
                  <img class="hoverImg" :src="scope.row.account.avatar"/>
                  <span class="nickname" >{{scope.row.account.nickname}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="thumb_image" label="商品图" min-width="120">
                <template slot-scope="scope">
                    <div style="display: flex;">
                      <img v-if="scope.row.thumb_image" style="width: 80px;height: 80px;border-radius: 8px;" :src="scope.row.thumb_image"/>
                    </div>
                </template>
              </el-table-column>
              <el-table-column prop="unique_id" label="商品ID" min-width="260"></el-table-column>
              <el-table-column prop="goods_name" label="商品名称" min-width="260"></el-table-column>
              <el-table-column prop="mark" label="备注" min-width="260">
              </el-table-column>
              <el-table-column label="状态" min-width="120">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status==0" type="info">上架中</el-tag>
                    <el-tag v-if="scope.row.status==1" type="success">已完成</el-tag>
                    <el-tag v-if="scope.row.status==-1" type="danger">已失败</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="createtime" label="创建时间" min-width="200">
              </el-table-column>
              <el-table-column label="操作" min-width="260">
                <template slot-scope="scope">
                  <div>
                    <el-button
                      @click="showDetail(scope.row)"
                      size="mini"
                      >详情</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="accbotm">
            <el-pagination
                  background
                  :page-size="formData.limit"
                  :current-page="formData.page"
                  layout="total, prev, pager, next, jumper"
                  :total="tableData.total"
                  @size-change="handleSizeChange"
                  @current-change="pageChange"
                />
          </div>
        </div>
      </div>
  
      <!-- 添加组件-->
      <el-dialog title="" :visible.sync="showTaskModal" width="800px" custom-class="dialog-box-cl" top="10vh" >
    
      </el-dialog>
       <!-- 添加组件-->
  
    
    </div>
  </template>
  <script>
   import {merGoodsListApi} from '@/api/api.js';

  export default {
    data() {
      return {
        detailModal:false,
        showTaskModal:false,
        imageSize:500,
        options: [],
        formData:{
          page:1,
          keyword:'',
          status:'all',
          limit:10
        },
        statusList:[
          {label:'全部',value:'all'},
          {label:'待处理',value:'0'},
          {label:'已完成',value:'1'},
          {label:'已失败',value:'-1'},
        ],
        goodsList:[],
        tableData:[],
        tableRowClassName({ row, rowIndex }) {
          return "tablerowcolor";
        },
        task_id:''
      };
    },

    mounted(){
      this.getList();
    },
    methods: {
      handleSelectionChange() {},
      search(){
        this.formData.page=1;
        this.tableData=[];
        this.getList();
      },
      getList(){
        let that=this;
        that.showTaskModal=false;
        merGoodsListApi(that.formData).then(res=>{
          console.log(res);
          that.tableData=res.data;
          that.goodsList=res.data.data;
          
        })
      },
      getstatusList(){
        let that=this;
        goodsGroupApi().then(res=>{
          that.statusList=res.data.labelList;
        });
      },
      changeKt(id){
        let that=this;
        checkKtApi({id:id}).then(res=>{
          that.$message.success(res.msg);
          that.getList();
        });
      },
      pageChange(e){
        this.formData.page=e;
        this.getList();
      },
      formChange(e){
        this.formData.status=e;
        this.formData.page=1;
        this.goodsList=[];
        this.getList();
      },
      addTask(){
        //this.showTaskModal=true;
        this.$router.push('/addGoods');
      },
      handleSizeChange(e){
        console.log(e);
        this.formData.page=1;
        this.goodsList=[];
        this.getList();
      },
      showDetail(item){
        this.detailModal=true;
        this.task_id=item.id;
      }
  
     
    },
  };
  </script>
  <style lang="scss" scoped>
  .hoverImg{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
  :deep(.el-table tr){
    background: #ECF2F9;
    text-align: center;
  }
  :deep(.el-table tr td){
    background: #ECF2F9;
    text-align: center;
  }
  
  .BOX-card{
    border-radius: 10px;;
  }
  
  .video-title{
    word-wrap: break-word;    /* 长单词换行 */
    white-space: normal; 
      width: 300px;    
      
    }
  
  .header-box{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .select-box{
    margin-left: 40px;;
  }
  :deep(.searchkey .el-input__inner){
    background: #ECF2F9;
    border:none;
    border-radius: 50px;
    width: 150px;
    color:#696969;
    font-size: 186px;
  }
  
  .searchkey{
    width: 200px;
    height: 38px;
    line-height: 38px;
    border:none;
    border-radius: 50px;
    margin-left: 18px;
    color:#696969;
    
  }
  :deep(.searchkey .el-input__inner){
      width: 300px;
    }
   
  input:focus{
    width: 300px;
    height: 38px;
    line-height: 38px;
    background: #ECF2F9;
    border:none;
    border-radius: 50px;
    margin-left: 18px;
    color:#696969;
  }
  
  
  
  :deep(.select-box .el-input__inner){
    background: #ECF2F9;
    border:none;
    border-radius: 50px;
    width: 200px;
    color:#696969;
    font-size: 16px;
  }
  
  .searchBtn{
    background-color: #2758FF;
    color:#fff;
    border-radius: 50px;
    margin-left: 18px;
  }
  .tzggicon {
    width: 6px;
    height: 22px;
    margin-right: 4px;
  }
  .BOX {
    .el-table .tablerowcolor {
      background: #ecf2f9;
      font-size: 20px;
    }
    background-color: #e1eaf2;
    width: 100%;
    height: calc(100vh - 100px);
    overflow-x: hidden;
    border-radius: 20px;
    padding: 20px;
    .acccont {
      width: 100%;
      height: calc(100vh - 200px);
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;
      .accbotm {
        width: 100%;
        height: 90px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .acctablebox {
        width: 100%;
        // height: calc(100vh - 360px);
        margin-top: 10px;
        background-color: #ecf2f9;
        border-radius: 8px;
      }
      .accconthead {
        width: 100%;
        height: 48px;
  
        display: flex;
        align-items: center;
        justify-content: space-between;
        .aaaaa,
        .bbbbb {
          background: #ecf2f9;
          padding: 6px;
          border-radius: 4px;
  
          font-weight: 400;
          color: #696969;
        }
        .bbbbb {
          padding: 6px 10px;
        }
      }
    }
    .accheadbox {
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .accitem {
        width: 270px;
        height: 120px;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        img {
          width: 72px;
          height: 72px;
        }
        .accright {
          flex: 1;
          height: 100%;
          font-weight: 400;
          font-size: 20px;
          color: #000000;
          span {
            font-size: 36px;
            color: #2758ff;
            line-height: 34px;
          }
        }
      }
    }
    .tzggtitle {
      font-size: 20px;
      color: #000;
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    .headbox {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .ccc {
      display: flex;
      .aaaaa,
      .bbbbb {
        background: #ecf2f9;
        padding: 6px;
        border-radius: 40px;
  
        font-weight: 400;
        color: #696969;
        margin-right: 20px;
      }
      .bbbbb {
        padding: 6px 10px;
      }
    }
  }
  </style>