
<template>
    <div>
      <div class="BOX BOX-card">
      
        <div class="shopitembox">
            <h1 style="padding:10px 20px;font-size: 20px;font-weight:600;">商户授权</h1>
          
     
            <div class="acccont">
  
              <div class="header-box">
                <div class="select-box">
                  <el-select v-model="formData.platform" placeholder="选择平台" @change="formChange">
                    <el-option
                      v-for="item in templeteList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      clearable="true"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
  
                  <el-input class="searchkey" v-model="formData.keyword" placeholder="请输入关键词" :clearable="true"></el-input>
                  <el-button class="searchBtn" @click="search">搜索</el-button>
                </div>
                <div>
                  <el-button class="addBtn" @click="addAccount">添加授权商户</el-button>
                </div>
              </div>
  
  
            <div class="acctablebox" v-if="accountList.length>0">
              <el-table
                :data="accountList"
                class="center-table"
                style="width: 100%; background-color: #ecf2f9"
                :row-class-name="tableRowClassName"
                :header-cell-style="{
                  backgroundColor: '#ECF2F9',
                  color: '#000',
                  fontSize: '18px',
                  fontWeight: 400,
                  textAlign:'center'
                }"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column prop="shopMenImg" label="头像" min-width="80">
                  <template slot-scope="scope">
                    <img class="hoverImg" :src="scope.row.avatar"/>
                  </template>
                </el-table-column>
                <el-table-column prop="shopMenImg" label="昵称" min-width="80">
                  <template slot-scope="scope">
                    <span class="nickname" >{{scope.row.nickname}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="" label="平台" min-width="60">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.platform=='douyin'">抖音</el-tag>
                    <el-tag v-if="scope.row.platform=='fasthand'"  type="warning">快手</el-tag>
                    <el-tag v-if="scope.row.platform=='redbook'" type="danger">小红书</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="shopMenImg" label="用户openID" min-width="120">
                  <template slot-scope="scope">
                    <span class="nickname" >{{scope.row.open_id}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="授权状态"
                  min-width="80"
                >
                <template slot-scope="scope">
                  <span class="nickname" v-if="scope.row.status==1" style="color:#67C23A;">已授权</span>
                  <span class="nickname" v-else style="color:#909399;">未授权</span>
                </template>
                </el-table-column>
                <el-table-column
                  prop="createtime"
                  label="创建时间"
                  min-width="80"
                ><template slot-scope="scope">
                  <span class="nickname">{{scope.row.createtime}}</span>
                </template>
               
                </el-table-column>
                <el-table-column label="操作" min-width="160">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                      class="addBtn"
                        @click="editShopclick(scope.row)"
                       
                        >刷新token</el-button
                      >
                      <el-button class="addBtn"  type="success" @click="$router.push('/shoplist')">货架管理</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-empty style="margin-top: 100px;" v-if="accountList.length<=0" :image="`${$IMG_URL}/imgs/empty/empty.png`" description="暂无数据" :image-size="300"></el-empty>
            <!-- 分页-->
        
            <div class="block" style="bottom:60px;">
                <el-pagination
                  background
                  :page-size="formData.limit"
                  :current-page="formData.page"
                  layout="total, prev, pager, next, jumper"
                  :total="tableData.total"
                  @size-change="handleSizeChange"
                  @current-change="pageChange"
                />
              </div>
          </div>
        </div>
      </div>
  
  <!-- 弹窗部分-->
  <el-dialog title="" :visible.sync="addModal" width="30%" custom-class="dialog-box-cl">
    <div slot="title" >
      <h3 style="font-weight: 700;padding:20px; ">选择添加账号的平台</h3>
    </div>
    <div class="dialog-content-box">
        <div class="pl-box-item" v-for="(item,index) in platformList" :key="index" @click="choosePlate(item)">
            <img class="pl-image" :src="item.image" > 
            <div  class="pl-name">{{ item.name }}</div>
        </div>
    </div>
    <div slot="footer" class="dialog-footer">
        <el-button @click="addModal = false">关 闭</el-button>
        
      </div>
  </el-dialog>
  <!-- 添加弹窗-->
  
    </div>
  </template>
   <script>
    import {accountListShopApi,webUrlShopApi,createAccountShopApi,accountDataShopApi} from '@/api/shop.js';
  export default {
    data() {
      return {
        addModal:false,
        imageSize:300,
        formData:{
          page:1,
          keyword:'',
          limit:10,
          platform:'all'
        },
        templeteList:[
          {label:'请选择平台',value:'all'},
          {label:'抖音',value:'douyin'},
          {label:'快手',value:'fasthand'},
          {label:'小红书',value:'redbook'}
        ],
        tableList: [{ wxstatus: "1" }],
        tableData:{
          total:0
        },
        accountList:[],
        accountData:{}
      };
    },
    computed:{
      platformList(){
        return this.$store.getters.platformList;
      }
    },
    mounted() {
      this.getAccountData();
      this.getList();
      console.log(this.platformList);
      let code=this.getData('code');
      let state=this.getData('state');
      if(code && state){
        this.createAccount(code,state);
      }
  
    
    },
    methods: {
      handleSelectionChange() {},
      tableRowClassName({ row, rowIndex }) {
        return "tablerowcolor";
      },
      formChange(e){
        this.formData.platform=e;
        this.formData.page=1;
        this.tableList=[];
       
      },
      handleSizeChange(e){
        console.log(e);
        this.formData.page=1;
        this.accountList=[];
        this.getList();
      },
      pageChange(e){
        this.formData.page=e;
        this.getList();
      },
      search(){
        this.formData.page=1;
        this.tableData=[];
        this.accountList=[];
        this.getList();
      },
      getList(){
        let that=this;
        accountListShopApi(that.formData).then(res=>{
          console.log(res);
          that.tableData=res.data;
          that.accountList=res.data.data;
        })
      },
      addAccount(){
        this.addModal=true;
      },
      getAccountData(){
        let that=this;
        accountDataShopApi().then(res=>{
         that.accountData=res.data;
        });
      },
      createAccount(code,platform){
        let that=this;
        createAccountShopApi({code:code,platform:platform}).then(res=>{
          setTimeout(function(){
            that.getList();
          },800);
        });
      },
      /**
       * 根据类型获取授权链接
       * @param {*} item 
       */
      choosePlate(item){
        let that=this;
        webUrlShopApi({platform:item.value}).then(res=>{
          if(res.data){
            window.open(res.data);
          }
        });
      },
      getData(name) {
          var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
          var URL = decodeURI(window.location.search);
          var r = URL.substr(1).match(reg);
          if (r != null) {
              return decodeURI(r[2]);
          }
          return null;
      },
    },
  };
  </script>
   
   <style scoped  lang="scss">
   /* 修改垂直滚动条 */
   ::-webkit-scrollbar {
      width: 10px !important; /* 修改宽度 */
      border-radius: 18px !important;
    }
     
    /* 修改滚动条轨道背景色 */
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1 !important;
      border-radius: 18px !important;
    }
     
    /* 修改滚动条滑块颜色 */
    ::-webkit-scrollbar-thumb {
      background-color: #ccc !important;
      border-radius: 18px !important;
    }
     
    /* 修改滚动条滑块悬停时的颜色 */
    ::-webkit-scrollbar-thumb:hover {
      background-color: #ccc !important;
    }
  //   :deep(.el-dialog__footer){
  //   padding:0 !important;
  // }
   .dialog-box-cl{
    border-radius: 20px;
   }
    .dialog-content-box{
      min-height: 200px;
      display: flex;
      flex-wrap: wrap;
      .pl-box-item{
        padding:20px;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .pl-image{
          width: 100px;
          height: 100px;
        }
        .pl-name{
          font-size: 16px;
          font-weight: 600;
          margin-top: 5px;
          color:#000;
        }
      }
    }
  .BOX-card{
    border-radius: 10px;;
  }
  :deep(.center-table td), 
  :deep(.center-table th) {
    text-align: center !important;
  }
  
  .header-box{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .select-box{
    display: flex;
  }
  :deep(.searchkey .el-input__inner){
    background: #ECF2F9;
    border:none;
    border-radius: 50px;
    color:#696969;
    font-size: 16px;
  }
  
  .searchkey{
    width: 300px;
    height: 38px;
    line-height: 38px;
    background: #ECF2F9;
    border:none;
    border-radius: 50px;
    margin-left: 18px;
    color:#696969;
  }
  
  
  
  
  :deep(.select-box .el-input__inner){
    background: #ECF2F9;
    border:none;
    border-radius: 50px;
    color:#696969;
    font-size: 16px;
  }
  
  .searchBtn{
    background-color: #2758FF;
    color:#fff;
    border-radius: 50px;
    margin-left: 18px;
  }
  .addBtn{
    background-color: #2758FF;
    color:#fff;
    margin-left: 18px;
  }
  .hoverImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .nickname{
    font-size: 16px; color: #000
  }
  .BOX {
    background-color: #e1eaf2;
    width: 100%;
    height: calc(100vh - 100px);
    overflow-x: hidden;
  }
  
  .el-table .tablerowcolor {
    background: #ecf2f9;
    font-size: 18px;
  }
  
  .tzggicon {
    width: 6px;
    height: 22px;
    margin-right: 4px;
  }
  .shopitembox {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
  
    .acccont {
      width: 100%;
      height: calc(100vh - 290px);
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;
      .accbotm {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .acctablebox {
        width: 100%;
        height: calc(100vh - 430px);
        margin-top: 10px;
        background-color: #ecf2f9;
        border-radius: 8px;
      }
      .accconthead {
        width: 100%;
        height: 48px;
  
        display: flex;
        align-items: center;
        justify-content: space-between;
        .aaaaa,
        .bbbbb {
          background: #ecf2f9;
          padding: 6px;
          border-radius: 4px;
  
          font-weight: 400;
          color: #696969;
        }
        .bbbbb {
          padding: 6px 10px;
        }
      }
    }
    .accheadbox {
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .accitem {
        width: 270px;
        height: 120px;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        img {
          width: 72px;
          height: 72px;
        }
        .accright {
          flex: 1;
          height: 100%;
          font-weight: 400;
          font-size: 18px;
          color: #000000;
          display: flex;
          flex-direction: column;
          .txt-t{
            font-size: 18px;
            color: #000000;
          }
          .text-n {
            font-size: 32px;
            color: #2758ff;
            line-height: 34px;
          }
        }
      }
    }
  }
  </style>
   